<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Four characteristic vibrational modes from the IR spectrum of ethanol are listed below.
        Match these modes to the approximate wavenumbers listed in the table.
      </p>

      <v-simple-table>
        <thead>
          <tr>
            <th style="font-size: 15px">Mode</th>
            <th style="font-size: 15px">
              <stemble-latex content="$3400$" />
              <stemble-latex content="$\text{cm}^{-1}$" />
            </th>
            <th style="font-size: 15px">
              <stemble-latex content="$2900$" />
              <stemble-latex content="$\text{cm}^{-1}$" />
            </th>
            <th style="font-size: 15px">
              <stemble-latex content="$1450$" />
              <stemble-latex content="$\text{cm}^{-1}$" />
            </th>
            <th style="font-size: 15px">
              <stemble-latex content="$1050$" />
              <stemble-latex content="$\text{cm}^{-1}$" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <stemble-latex content="$\text{a) O-H stretch}$" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="3400" value="3400" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="2900" value="2900" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="1450" value="1450" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="1050" value="1050" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{b) C-O stretch}$" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="3400" value="3400" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="2900" value="2900" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="1450" value="1450" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="1050" value="1050" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{c) H-C-H bend}$" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="3400" value="3400" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="2900" value="2900" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="1450" value="1450" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="1050" value="1050" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{d) C-H stretch}$" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="3400" value="3400" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="2900" value="2900" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="1450" value="1450" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="1050" value="1050" />
              </v-radio-group>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'ChemUCI41LBA6A16',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
      },
    };
  },
};
</script>
